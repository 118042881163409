
.unread-bubble {
	background-color: red;
	color: white;
	padding: 0px 7px;
	border-radius: 50%;
	position: absolute;
	right: 23px;
}

.alert {
	position: fixed;
	top: 30px;
	width: auto;
	align-self: center;
	margin: 0 auto;
	z-index: 9999;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.v_menu_btn {
	position: absolute;
	right: 80px;
}

.v_avatar {
	width: 32px;
	height: 32px;
	position: absolute;
	right: 20px;
}

.v_message {
	margin-right: 20px;
}

.v_list_item:hover {
	background: rgba(24, 103, 192, 0.12);
	border-radius: 25px;
}

.v_list_item::before {
	display: none;
}

.v_icon_name {
	margin-right: 16px !important;
	margin-left: 16px;
}

.v_selectItem {
	background: rgba(24, 103, 192, 0.12);
	border-radius: 25px !important;
}

.v_title_name {
	font-size: 14px !important;
	color: #333;
	font-weight: bold !important;
}

.v-application--wrap {
	min-height: calc(100vh - 64px) !important;
}

.app-top-select {
	max-width: 120px;
	border-radius: 20px;
	position: relative;
	top: 5px;
	left: 10px;
	height: 50px;
	font-size: 12px;

	.v-input__control {
		.v-input__slot {
			box-shadow: none !important;

			.v-icon {
				color: #fff;
			}
		}
	}
}

.app_bar {
	.v-select.v-input--dense .v-select__selection--comma {
		color: #fff !important;
	}
}

.app-content {
	margin: 64px 0px 0px 160px;
	background: #f8f8f8;
	width: 92%;
    height: 90%;
    overflow-y: auto;
}

.app-maun-content {
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 0 4px #f0f0f0;
}
.v-main{
	padding: 0px !important;
	height: 106%;
}
