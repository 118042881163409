
.zanwu{
    width: 200px;
    text-align: center;
    margin: 100px auto;
    color: #999;
    div{
      width: 100%;
      text-indent:50px;
    }
}
